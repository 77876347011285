<template>
  <div class="d-flex flex-column text-center">
    <date-picker
      class="my-4"
      label="Process Time"
      showTime
      :defaultNowOnNull="false"
      :show-hint="false"
      :show-icon="false"
      v-model="worksheet.processTime"
      outlined
    />

    <div class="d-flex justify-center mx-2 my-4">
      <v-btn @click="updateWorksheet(worksheet)" color="primary">{{
        $vuetify.lang.t("$vuetify.base.update")
      }}</v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    worksheet: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {};
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),

    hasEntries() {
      return this.worksheet.entries && this.worksheet.entries.length > 0;
    },
  },
  components: {
  },
  methods: {
    ...mapActions("processWorksheet", [
      "updateProcessWorksheet",
      "deleteProcessWorksheet",
    ]),
    ...mapActions("messages", ["addErrorMessage", "addSuccessMessage"]),

    updateWorksheet(worksheet) {
      const payload = Object.assign({}, worksheet);
      delete payload.files;
      this.updateProcessWorksheet(payload);

      this.$emit("update:worksheet", worksheet);
      this.$emit("updated", worksheet);
    },
  },
};
</script>

<style></style>
